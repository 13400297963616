.feedInputContainer textarea{
    width:100%;
    min-height: 90px
}
.cardContainer::-webkit-scrollbar {
    width: 100px
    
  }
.cardContainer{

    overflow: auto;
    height: 80vh;
}
@media(max-height:600px){
    .cardContainer{
        height: 70vh;
    }
}