.chatTable {
  width: 90%;
  max-width: 800px;
  max-height: 600px;
  display: block;
  overflow: auto;
  overflow-x: hidden;
  border-collapse: collapse;
  background-color: white;
  text-align: center;
}

.chatTable thead,
.chatTable tbody,
.chatTable thead tr,
.chatTable tbody tr,
.chatTable thead tr th,
.chatTable tbody tr td {
  display: block;
  width: 100%;
}
.userImage {
  height: 50px;
  width: 50px;
}
.profileImage {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.userName {
  font-size: 18px;
  font-weight: 800;
}
.dashboardTable {
  text-align: center;
}
