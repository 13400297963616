.container {
  max-height: 100vh; /* Set the max height to the viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 40px 40px;
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.new-request-button {
  background-color: darkgreen;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.data-table th {
  background-color: darkgreen;
  color: white;
  text-align: left;
}

.data-table td {
  background-color: white;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 50%;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.form-buttons {
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}

form label {
  display: block;
  margin-bottom: 10px;
}

.pagination {
  margin-top: 10px; /* Adjust as needed */
  text-align: center; /* Center align the buttons */
}

.pagination button {
  padding: 8px 16px; /* Adjust padding as needed */
  margin: 0 5px; /* Adjust margin between buttons */
  font-size: 14px; /* Adjust font size */
  background-color: darkgreen; /* Button background color */
  color: white; /* Button text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: darkgreen; /* Darker background color on hover */
}

.pagination button:disabled {
  opacity: 0.6; /* Reduce opacity for disabled state */
  cursor: not-allowed; /* Change cursor to not-allowed */
}

.pagination span {
  margin: 0 10px; /* Adjust margin around the current page indicator */
  font-size: 14px; /* Adjust font size */
  color: #333; /* Text color */
}

.select-padding {
  padding-left: 10px; /* Adjust the padding value as needed */
}
form input {
  width: calc(100% - 20px);
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

form button {
  background-color: darkgreen;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-bar {
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ddd;
  width: 70%;
}

.sort-dropdown {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}
.status {
  padding: 5px 10px;
  border-radius: 10px;
  color: white;
}

.status.pending {
  background-color: yellow;
  color: black; /* Since yellow background needs darker text */
}

.status.in-progress {
  background-color: orange;
}

.status.tested {
  background-color: green;
}
