.registerFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #f3f6f8;
}

.registerLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding: 20px 0;
  border-bottom: 1px solid #d1d5db;
}

.LoginScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background-color: #f3f6f8;
}
.passwordFieldStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.passwordVisibleIcon {
  position: relative;
  right: 40px;
  bottom: 14px;
  height: 0px;
  width: 0px;
  border: 2px solid #d1d5db;
  border-left: none;
  background-color: #fff;
}
