.container {
  margin: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar {
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ddd;
  width: 200px;
}

.loading-spinner {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.pagination {
  margin-top: 10px; /* Adjust as needed */
  text-align: center; /* Center align the buttons */
}

.pagination button {
  padding: 8px 16px; /* Adjust padding as needed */
  margin: 0 5px; /* Adjust margin between buttons */
  font-size: 14px; /* Adjust font size */
  background-color: darkgreen; /* Button background color */
  color: white; /* Button text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: darkgreen; /* Darker background color on hover */
}

.pagination button:disabled {
  opacity: 0.6; /* Reduce opacity for disabled state */
  cursor: not-allowed; /* Change cursor to not-allowed */
}

.pagination span {
  margin: 0 10px; /* Adjust margin around the current page indicator */
  font-size: 14px; /* Adjust font size */
  color: #333; /* Text color */
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.data-table th {
  background-color: darkgreen;
  color: white;
  text-align: left;
}

.data-table td {
  background-color: white;
}

.process-button {
  background-color: darkgreen;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.viewReport-button {
  background-color: yellow;
  color: black;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 50%;
  height: 80%;
  position: relative;
  overflow-y: auto;
}

.close {
  position: absolute;
  top: 0px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
}

.scrollable-form {
  max-height: calc(100% - 50px);
  overflow-y: auto;
}

.form-element {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-element span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 50%;
}

.form-element input {
  flex-grow: 1;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.form-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
}

.form-buttons button {
  background-color: darkgreen;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.indicator-nitrogen {
  background-color: red;
}

.indicator-phosphorus {
  background-color: blue;
}

.indicator-potassium {
  background-color: green;
}

.indicator-sulfur {
  background-color: yellow;
}

.indicator-copper {
  background-color: purple;
}

.indicator-zinc {
  background-color: orange;
}

.indicator-manganese {
  background-color: pink;
}

.indicator-iron {
  background-color: brown;
}

.indicator-boron {
  background-color: cyan;
}

.indicator-organiccarbon {
  background-color: magenta;
}

.indicator-conductivity {
  background-color: lime;
}

.indicator-ph {
  background-color: navy;
}

.indicator-urea {
  background-color: olive;
}

.indicator-dap {
  background-color: teal;
}

.indicator-mop {
  background-color: maroon;
}

.indicator-zincsulfate {
  background-color: coral;
}

.indicator-gypsum {
  background-color: chocolate;
}

.indicator-borax {
  background-color: crimson;
}

.indicator-ferroussulfate {
  background-color: darkcyan;
}

.indicator-manganesesulfate {
  background-color: darkmagenta;
}

.indicator-coppersulfate {
  background-color: darkorange;
}

.indicator-lime {
  background-color: darkred;
}

.indicator-calciumsulfate {
  background-color: darkslateblue;
}
