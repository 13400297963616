.container {
  padding: 20px;
  box-sizing: border-box;
}

.cropModalHeaders {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.key-value-pair {
  margin-bottom: 10px;
}

input[type="file"] {
  margin-top: 10px;
}

button {
  margin-top: 20px;
}

button.cancel-btn {
  background-color: #f44336;
  color: white;
}

button.update-btn {
  background-color: #4caf50;
  color: white;
}

button.add-btn {
  background-color: #2196f3;
  color: white;
}

h3 {
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin: 10px;
}

button:disabled {
  background-color: #bdbdbd;
  cursor: not-allowed;
}
