.cropModalHeaders{
    margin: 5px ;
    font-size: 20px;
}
.CropContainer{
   display: flex;
   justify-content: flex-start;
   flex-wrap: wrap;
   border-radius: 30px;
background-color: rgba(255,255,255,0.4);
   width: 100%;
  height: 700px

}
.cropImageNameHolder {
    border: 1px solid black;
    border-radius: 30px;
    background-color: #E4EFE7;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    padding: 20px;
}
.CropContainer img{
    height: 100px;
    width: 100px;
    border-radius: 50%;
}