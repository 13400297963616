.userTable{
    width :90vw;
    max-width: 1000px;
    background-color: white;
    border : 1px solid black;
    border-collapse: collapse;
    max-height: 700px;
 
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    text-align: center;
}
.selectUser{
    height: 30px;
}
.userTable  tr,
.userTable th,
.userTable td{
   display: flex;
   justify-content: center;
    width:  100%

}
.userData td{
    align-items: center;
    padding: 2px;
}
.userTableProfile{
    height: 40px;
    width: 40px;
    border-radius: 50%;
}
.userData{
    height: 60px;
}