.container{
    padding: 16px;

}
.taskAssignFormContainer{
padding: 16px;
}
.taskAssignFormFooter{
    height: 100%;
    padding:0 16px;
}
.heading{
    text-align: center;
}
.profileImageUserCard{
    width: 100px;
    height: 100px;
    border-radius: 50px;
}
.cardFooter{
    padding: 4px 16px;
    width:100%;
    height:100%;
    
}
.deleteContainerChild{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
}
.deleteButtonContainer{
    display: flex;
    justify-content: space-between;
    width: 280px;

}