.table-container {
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

.custom-table th,
.custom-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.custom-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.custom-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.custom-table tr:hover {
  background-color: #f1f1f1;
}

.custom-table th,
.custom-table td {
  min-width: 120px;
}

@media (max-width: 768px) {
  .custom-table th,
  .custom-table td {
    font-size: 14px;
    padding: 6px;
  }
}
