.requestTable {
  margin-top: 30px;
  width: 80vw;
  font-size: 20px;
  text-align: center;
  background-color: white;
  border-collapse: collapse;
}
.requestTable td {
  padding: 10px;
}
.reportButton{
   padding: 100px;
   width: 50vw;

}