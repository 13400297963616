.scriptTable {
    width: 90%;
    max-width: 800px;
    height: 650px;
    display: block;
    overflow: auto;
    overflow-x: hidden;
    border-collapse: collapse;
    background-color: white;
    text-align: center;

}
.scriptTable tr{
    padding: 5px;
    border-bottom: 1px solid black;
}
.scriptTable thead,
.scriptTable tbody,
.scriptTable thead tr,
.scriptTable tbody tr,
.scriptTable thead tr th,
.scriptTable tbody tr td {
    display: block;
    width: 100%;

}