
.HeadingForQR {
    text-align: center;
}

.dailogContainer {
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    background-color: red;
}
.modalQRcontainer{
    height: 100%;
    width: 100%;
}
.modalQRcontainer table{
    height: 1000px;
    width: 100%;
}
.QRTable td{
    padding: 10px;
}
.Component.Row {
    display: flex;
    justify-content: space-between;
}
.QRTable{
    max-height: 100px;
    border-collapse: collapse;
    overflow-y: scroll;
    height: 100%;
    width: 90%;
    border: 1;
}
.QRTable td{
    text-align: center;
}
.agristickDataTable{
    width: 100%;
    display: inline-block;
    background-color: white;
    overflow-y: scroll;
     height: 630px;
    text-align: center;
    font-size: 20px;
    border-collapse: collapse;
}
.agristickDataTable td{
    width: 300px;
}
.agristickDataTable .tableHEader{
    height:40px;
    position: static;
    width: 100%;
    background-color: rgb(201, 241, 201);
    max-height: 60px;
}
.agriStickDataRow{
    height: 60px;
}
.agriStickDataRow td{
    padding:  10px ;
}