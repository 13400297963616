.userProfileContainer {
  width: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.userProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: #f3f6f8;
  border-radius: 10px;
  padding: 10px;
}
.margin-0 {
  margin: 0;
}
