.addFeedModelContainer {
  height: 80vh;
}
#feedImageInput {
  display: none;
}
.selectCategory {
  height: 30px;
}
.addFeedModelContainer .feedImage {
  min-width: 260px;
  width: 40vw;
  height: 50vh;
}
.addFeedModelContainer h1 {
  text-align: center;
  margin: 5px 0;
}
.addFeedModelContainer h3 {
  margin-top: 5px;
  margin-bottom: 10px;
}
select {
  padding-right: 20px;
}
.CaptionInput {
  margin: 10px;
  border: none;
  width: 80%;
  border-bottom: 3px solid green;
}
.CommentProfileImage {
  display: none;
  height: 50px;
  width: 50px;
  display: block;
  border-radius: 50%;
  margin-right: 5px;
}
.commentContainer {
  width: 100%;
  height: inherit;
  position: relative;
}
.commentInput {
  position: absolute;
  width: inherit;
  bottom: 10px;
}
.feedVideo {
  height: 300px;
  width: 300px;
}
