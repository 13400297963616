.CategoryTable{
    width: 90%;
    max-width: 600px;
    max-height: 600px;
    overflow-y: scroll;
    border-collapse: collapse;
    background-color: white;
    text-align: center;
}
.CategoryTable *{
    font-size: 20px;
    padding:5px
} 