.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(10px);
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  top: 0;
  left: 0;
  height: 100vh;
}

.modal_download_button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 3rem;
  width: 150px;
  height: 40px;
  transition: all 0.2s linear;
  margin-left: auto; /* Align to the right */
}

.modal_download_button:hover {
  background-color: white;
  color: #4caf50;
  border: 1px solid #4caf50;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  padding: 20px; /* Added padding to the body */
}

.card {
  background-color: #faeecb;
  padding: 10px;
  margin: 0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1200px; /* Maximum width for large screens */
  height: auto;
  border: 2px solid #4caf50;
  page-break-inside: avoid;
  overflow: hidden; /* Prevent overflow */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.logo img {
  height: 40px;
  margin-right: 30px;
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.title {
  text-align: center;
  flex: 1; /* Allow the title to take available space */
}

.title h1 {
  margin: 0;
  color: #4caf50;
  font-size: 1em;
}

.title h2 {
  margin: 0;
  color: #333;
  font-size: 0.8em;
}

.title p {
  margin: 0;
  color: #555;
  font-size: 0.7em;
}
h3 {
  font-size: 0.9em; /* Adjust this value as needed */
}
.content {
  overflow: auto; /* Ensure content is scrollable if it overflows */
  max-height: 80vh; /* Adjust to fit within the card */
}

.content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.content table,
.content th,
.content td {
  border: 1px solid #ddd;
}

.content th,
.content td {
  padding: 6px;
  text-align: left;
  font-size: 0.6em;
}

.content th {
  background-color: #f2f2f2;
}

.content tr {
  background-color: #fff;
}

.content .highlight-default {
  background-color: white;
}

.content .highlight-yellow {
  background-color: #ffffcc;
}

.content .highlight-dark-green {
  background-color: #88fa88;
}

.content .highlight-green {
  background-color: #ccffcc;
}

.content .highlight-red {
  background-color: #ffcccc;
}

/* pH Condition Classes */

/* Very Strongly Acidic */
.highlight-red {
  background-color: #ff4d4d; /* Red */
}

/* Strongly Acidic */
.highlight-orange {
  background-color: #ff9933; /* Orange */
}

/* Moderately Acidic */
.highlight-yellow {
  background-color: #ffcc00; /* Yellow */
  color: black;
}

/* Slightly Acidic */
.highlight-light-yellow {
  background-color: #ffff99; /* Light Yellow */
  color: black;
}

/* Neutral */
.highlight-green {
  background-color: #66cc66; /* Green */
}

/* Moderately Alkaline */
.highlight-light-blue {
  background-color: #99ccff; /* Light Blue */
  color: black;
}

/* Strongly Alkaline */
.highlight-blue {
  background-color: #3399ff; /* Blue */
}

/* Default */
.highlight-default {
  background-color: #e0e0e0; /* Grey */
  color: black;
}

.footer {
  text-align: center;
  margin-top: 10px;
}

.footer p {
  color: #d9534f;
  font-size: 0.8em;
}
