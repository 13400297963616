.ReportContainer{
    background-color: red;
    width: 100vw;
  
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: scroll;

}
.reportTable ,.solution1 , .solution2{
  
    border-collapse: collapse;
    width: 85vw;
    text-align: center;
}
.reportTable th , .solution1 th , .solution2 th{ 
    padding:10px;
    font-size: 25px;
    background-color: #F5F5F5;
}
.reportTable td{
    padding: 15px;
    font-size: 20px;
}
.less{
    background-color: #F96767;
}
.sufficient{
    background-color: #7DC76A;
}
.solution1{
    width: 40vw;
    margin: 20px;
}
.solution2{
    margin: 20px;
    width: 40vw;
}
.solutonHeading{
    margin: 4px;
    font-size: 30px;
}